<template>
  <div id="app">
    <div class="naviFixed">
      <li class="background background_up" @click="onclickToTop" v-if="topShow">
        <div class="wrap_btn1">
          <div class="wrap">
            <svg-icon icon-file-name="up" class="svg" />
          </div>
        </div>
      </li>
      <ul>

        <li
          v-for="(item, index) in fixedArr"
          :key="index"
          class="background"
          @mouseenter="handleSelect(index)"
          @mouseleave="handleLeave(5)"
        >
          <div :class="currentIndex === index ? 'wrap_btnActive' : 'wrap_btn'">
            <div class="wrap">
              <img
                :src="item.imgShowName"
                width="25px"
                height="25px"
                v-show="currentIndex == index"
              />

              <svg-icon
                :icon-file-name="item.imgName"
                class="svg"
                v-show="currentIndex !== index"
              />
              <span>{{ item.description }} </span>
            </div>
          </div>
          <div
            class="wrap_left1"
            v-show="currentIndex === index && !item.isImage && index == 0"
          >
            <div class="wrap_left11">
              <p>服务热线</p>
              <span>028-64722996</span>
              <p>企业合作</p>
              <span> 19113908696（唐先生） </span>
              <span>19115921051（张先生） </span>
            </div>
          </div>
          <div
            class="wrap_left1"
            v-show="currentIndex === index && !item.isImage && index == 2"
          >
            <div class="wrap_left11">
              <p>联系QQ</p>
              <span>{{ item.detail }}</span>
            </div>
          </div>
          <div
            class="wrap_left1"
            v-show="currentIndex === index && item.isImage"
          >
            <!-- v-show="currentIndex === index && item.isImage"
 -->
            <div class="wrpa-left12">
              <el-image
                :src="item.detail"
                fit="fill"
                style="
                  height: 100px;
                  width: 100px;
                  display: flex;
                  vertical-align: middle;
                  margin: 0 35px 0px 35px;
                "
              ></el-image>
              <span>唐先生</span>
              <el-image
                :src="item.detail2"
                fit="fill"
                style="
                  height: 100px;
                  width: 100px;
                  display: flex;
                  vertical-align: middle;
                  margin: 20px 35px 0px 35px;
                "
              ></el-image>
              <span>张先生</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="header-box">
      <div class="header">
        <!-- :style="{'width': screenWidth + 'px'} -->

        <div class="heade_logo">
          <img
            src="./assets/image/logo.png"
            style="display: inline-block; width: 142px; height: 60px"
          />
        </div>

        <div class="nav">
          <el-menu
            :default-active="this.$route.path"
            router
            class="el-menu-demo"
            mode="horizontal"
            text-color="white"
            active-text-color="#FD8843"
            @select="menuSelect"
          >
            <el-menu-item index="/home">首页</el-menu-item>
            <el-menu-item index="/airInternational">国际机票</el-menu-item>
            <el-menu-item index="/airNational">国内机票</el-menu-item>
            <el-menu-item index="/movie">电影票</el-menu-item>
            <el-menu-item index="/company">关于亿旅</el-menu-item>
          </el-menu>
        </div>

        <div class="tel" :style="screenWidth <= 1243 ? 'display:none' : ''">
          <ul>
            <li>
              <svg-icon icon-file-name="tel" />
              <span class="tel24">24小时服务热线</span>
            </li>
            <li>
              <span class="tel028">028-64722996</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <transition name="el-fade-in-linear">
      <router-view :key="dataKey">
        <div></div>
      </router-view>
    </transition>
  </div>
  <!-- </div> -->
</template>

<script>
// import DevicePixelRatio from "./utils/devicePixelRatio";

export default {
  name: "App",
  data() {
    return {
      currentIndex: null,
      topShow: false,

      dataKey: "/home",
      screenWidth: 1290,
      fixedArr: [
        {
          imgShowName: require("./assets/image/telShow.png"),
          description: "电话咨询",
          imgName: "telconsultation",
          isImage: false,
          detail: "18780167804",
        },
        {
          imgShowName: require("./assets/image/vxShow.png"),
          imgName: "vxconsultation",
          description: "微信咨询",
          isImage: true,
          detail: require("./assets/image/jiang.png"),
          detail2: require("./assets/image/zhang.png"),
        },
        {
          imgShowName: require("./assets/image/qqShow.png"),
          imgName: "qqconsultation",
          description: "QQ咨询",
          isImage: false,
          detail: "2291182207",
        },
      ],
    };
  },

  created() {
    this.getWidth();

    // new DevicePixelRatio().init();
  },

  watch: {
    screenWidth: function (n, o) {
      console.log(n)
      if (n) {
        this.getWidth();
      }
      if (n <= 1200) {
        console.log("屏幕宽度小于1200了");
      } else {
        console.log("屏幕宽度没有小于1200");
      }
    },
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听页面滚动;

    this.getWidth();
  },
  methods: {
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 800) {
        this.topShow = true;
      }
      if (scrollTop <= 0) {
        this.topShow = false;
      }
    },

    onclickToTop() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 0) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    },
    getWidth() {
      const that = this;
      window.onresize = () => {
        return (() => {
          window.screenWidth = document.body.clientWidth;
          that.screenWidth = window.screenWidth;
          console.log(that.screenWidth);
        })();
      };
    },
    menuSelect(val) {
      this.dataKey = val;
    },
    handleLeave(val) {
      this.currentIndex = val;
    },
    handleSelect(val) {
      this.currentIndex = val;
    },
  },
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
.naviFixed {
  position: fixed;
  top: 30%;
  right: 8px;
  z-index: 999;
  .background_up {
    margin-bottom: 10px;
    border-radius: 4px 4px 4px 4px !important;
  }

  .background:first-child {
    border-radius: 4px 4px 0px 0px;
  }
  .background:last-child {
    border-radius: 0px 0px 4px 4px;
  }
  .background {
    list-style: none;
    position: relative;
    background: #ffffff;
    .wrap_btn1 {
      width: 90px;
      height: 90px;
      cursor: pointer;
      .wrap {
        padding-top: 28px;
        .svg {
          width: 25px;
          height: 25px;
          line-height: 25px;
          display: block;
          margin: 0 auto;
        }
      }
    }
    .wrap_btn {
      width: 90px;
      height: 90px;
      cursor: pointer;
      .wrap {
        padding-top: 18px;
        .svg {
          width: 25px;
          height: 25px;
          line-height: 25px;
          display: block;
          margin: 0 auto;
        }
        span {
          display: block;
          font-size: 13px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          margin-top: 9px;
          color: #333333;
        }
      }
    }

    .wrap_left1 {
      vertical-align: center;
      background-color: white;
      position: absolute;
      width: 167px;
      padding-right: 3px;
      top: 0;
      box-shadow: 0px 3px 5px 1px rgba(67, 97, 183, 0.3);
      border-radius: 4px 4px 4px 4px;
      left: -175px;
      z-index: 999;

      .wrap_left11 {
        margin: 0 0 10px 7px;
        padding:10px;
        text-align: start;
        p {
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #fd8843;
          padding: 10px 0 10px 0;
        }
        span {
          margin: 0 auto;
          display: block;
          font-size: 10px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
        }
        span:nth-child(3) {
          margin-bottom: 10px;
        }
      }
      .wrpa-left12 {
        padding: 20px 0;
        span {
          margin: 5px 0;
        }
      }
    }
    .wrap_left {
      vertical-align: center;
      background-color: white;
      position: absolute;
      width: 110px;
      padding-right: 10px;
      top: 20px;
      left: -135px;

      p {
        text-align: center;
      }
    }

    .wrap_left::before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      border-left: 10px solid white;
      border-top: 10px solid transparent;
      border-right: 0px solid white;
      border-bottom: 10px solid transparent;
      right: -10px;
      top: 9px;
    }

    .wrap_btnActive {
      width: 90px;
      height: 90px;
      background: linear-gradient(180deg, #f58a2e 0%, #ffcd72 100%);
      // border-radius: 5px 0px 0px 0px;
      opacity: 1;

      .wrap {
        padding-top: 18px;
        // line-height: 81px;
        .svg {
          width: 25px;
          height: 25px;
          display: block;
          margin: 0 auto;
          color: #ffffff;
        }
        span {
          display: block;
          font-size: 9px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          margin-top: 9px;
          color: #ffffff;
        }
      }
    }
  }
}
ul li {
  list-style: none;
}

.header-box {
  .header {
    background: rgba(0, 0, 0, 0.5);
    height: 80px;
    position: relative;
    // top: -10px;
    // display: flex;
    // justify-content: space-between;
    z-index: 99;
    width: 100%;
    // min-width: 1200px;
    .heade_logo {
      position: relative;
      top: -32px;
      width:150px;
      right: 61px;
      margin-left: 119px;
      display: inline-block;
      padding: 7px 0 0px 0;
    }

    .nav {
      display: inline-block;
      line-height: 800px;
      padding: 14px 14px;
      width: 700px;
      .el-menu-demo {
        background-color: transparent !important;
        .el-menu-item {
          font-size: 20px;
          color: #ffffff;
          font-family: PingFang SC-Medium, PingFang SC;
          width: 20%;
        }
      }

      .el-menu.el-menu--horizontal {
        border-bottom: none !important;
      }

      .el-menu-item.is-active {
        background-color: transparent !important;
        color: #fd8843;
        font-weight: bolder;
        font-size: 24px;
        padding-bottom: 63px;
        // margin: 8px 0;
        border-bottom: 5px solid #fd8843 !important;
      }

      .el-menu-item:hover {
        background-color: transparent;
        color: #fd8843 !important;
        // background: linear-gradient(270deg, #f2f7fc 0%, #fefefe 100%) !important;
      }
    }

    .tel {
      position: relative; top: -32px;
left:95px;
      display: inline-block;
      padding: 10px 0;
      
      margin-right: 128px;
      span {
        display: inline-block;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 0px;
        margin: 16px 0;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }

      .tel24 {
        display: inline-block;
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        // font-weight: 500;
        color: #ffffff;
        // line-height: 0px;
      }

      .tel028 {
        font-size: 24px;
        font-style: italic;
        font-family: Arial-Bold Italic, Arial;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }

 
}

@media screen and (max-width: 1400px) {
  html {
    overflow: auto;
  }
  .header-box{
    .header{
      width: 1400px;
      .nav{
        left: 23%;
      }
      .tel{
        display: none;
      }
    }
  }
}

@media screen and (min-width: 2560px) {
  // html {
  //   // font-size: 106.66px !important;
  //   width: 2561px;
  //   overflow: auto;
  // }
}

@import "./assets/styles/demo.css";

body {
  font-family: BlinkMacSystemFont, Helvetica, Arial, Tahoma, PingFang SC,
    Hiragino Sans GB, Lantinghei SC, Microsoft YaHei, sans-serif !important;
  color: #333;
  // width:1920px;

  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 1200px;
  overflow: auto;
}
</style>
