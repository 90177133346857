<template>
  <div>
    <!-- 头部 -->
    <div class="header-box">
    </div>
    <!-- 亿旅服务 -->
    <div class="service">
      <div class="header_bottom">
        <ul class="box">
          <li class="container">
            <div class="container_padding">
              <div class="icon">
                <svg-icon
                  icon-file-name="oneTop"
                  style="height: 100px; width: 100px"
                />
              </div>
              <div class="text">
                <p class="text_title">一站式服务</p>
                <p class="text_container">客户至上，提供一站式解决方案</p>
              </div>
            </div>
          </li>
          <li class="container">
            <div class="container_padding">
              <div class="icon">
                <svg-icon
                  icon-file-name="resource"
                  style="height: 100px; width: 100px"
                />
              </div>
              <div class="text">
                <p class="text_title">丰富资源整合</p>
                <p class="text_container">全球实力供应商，提供资源保障</p>
              </div>
            </div>
          </li>
          <li class="container">
            <div class="container_padding">
              <div class="icon">
                <svg-icon
                  icon-file-name="money"
                  style="height: 100px; width: 100px"
                />
              </div>
              <div class="text">
                <p class="text_title">轻松结算</p>
                <p class="text_container">全程在线，无需等待</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div>
        <p class="title">亿旅服务</p>
      </div>
      <div>
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in serviceList"
              :key="index"
            >
              <div>
                <div v-for="(item1, index1) in item" :key="index1" class="api">
                  <img :src="item1.img" width="381px" height="220px" />
                  <p class="api_title">{{ item1.p1 }}</p>
                  <p class="api_content">
                    {{ item1.p2 }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>

        <!--分页器。如果放置在swiper外面，需要自定义样式。-->
      </div>
    </div>


    <!-- 优势 -->
    <div class="advantage">
      <div class="title">
        <p>亿旅优势</p>
      </div>
      <div class="ad_content">
        <ul>
          <li v-for="(item, index) in adlist1" :key="index">
            <div class="advantage_float">
              <div class="icon">
                <svg-icon
                  :icon-file-name="item.name"
                  style="height: 100px; width: 100px"
                />
              </div>
              <div class="text">
                <p class="text_title">{{ item.title }}</p>
                <p class="text_container">
                  {{ item.description }}
                </p>
              </div>
            </div>
          </li>
        </ul>
        <ul>
          <li v-for="(item, index) in adlist2" :key="index">
            <div class="advantage_float">
              <div class="icon">
                <svg-icon
                  :icon-file-name="item.name"
                  style="height: 100px; width: 100px"
                />
              </div>
              <div class="text">
                <p class="text_title">{{ item.title }}</p>
                <p class="text_container">
                  {{ item.description }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="step">
      <p class="title">对接流程</p>
      <ul>
        <li>
          <svg-icon
            icon-file-name="step1"
            style="height: 125px; width: 108px"
          />
        </li>
        <li>
          <svg-icon
            icon-file-name="step2"
            style="height: 125px; width: 108px"
          />
        </li>

        <li>
          <svg-icon
            icon-file-name="step3"
            style="height: 125px; width: 108px"
          />
        </li>

        <li>
          <svg-icon
            icon-file-name="step4"
            style="height: 125px; width: 108px"
          />
        </li>

        <li>
          <svg-icon
            icon-file-name="step5"
            style="height: 125px; width: 108px"
          />
        </li>

        <li>
          <svg-icon
            icon-file-name="step6"
            style="height: 125px; width: 108px"
          />
        </li>
      </ul>
      <div class="step_container">
        <el-steps align-center>
          <el-step title="01" description="需求沟通" icon="el-icon-picture">
            <template slot="icon">
              <div
                style="
                  width: 24px;
                  height: 24px;
                  background: #fec86d;
                  border-radius: 50%;
                "
              ></div>
            </template>
          </el-step>
          <el-step title="02" description="接口测试" icon="el-icon-picture">
            <div
              slot="icon"
              style="
                width: 24px;
                height: 24px;
                background: #fec86d;
                border-radius: 50%;
              "
            ></div>
          </el-step>
          <el-step title="03" description="协议签订" icon="el-icon-picture">
            <div
              slot="icon"
              style="
                width: 24px;
                height: 24px;
                background: #fec86d;
                border-radius: 50%;
              "
            ></div>
          </el-step>
          <el-step title="04" description="技术对接" icon="el-icon-picture">
            <div
              slot="icon"
              style="
                width: 24px;
                height: 24px;
                background: #fec86d;
                border-radius: 50%;
              "
            ></div>
          </el-step>
          <el-step title="05" description="平台联调" icon="el-icon-picture">
            <div
              slot="icon"
              style="
                width: 24px;
                height: 24px;
                background: #fec86d;
                border-radius: 50%;
              "
            ></div>
          </el-step>
          <el-step title="06" description="部署上线" icon="el-icon-picture">
            <div
              slot="icon"
              style="
                width: 24px;
                height: 24px;
                background: #fec86d;
                border-radius: 50%;
              "
            ></div>
          </el-step>
        </el-steps>
      </div>
    </div>

    <!-- bottom -->
    <div class="bottom">
      <ul style="width: 85%" class="bottom_title">
        <li class="el">
          <ul>
            <li>
              <img
                src="../../assets/image/bottomlogo.png"
                width="236px"
                height="56px"
              />
            </li>
            <li class="logoName">
              <svg-icon
                icon-file-name="tel"
                style="height: 27px; width: 27px"
              />
              <p>028-64722996</p>
            </li>
          </ul>
        </li>
        <li class="el">
          <ul>
            <li>
              <p class="el_title">首页</p>
            </li>
            <li>
              <router-link :to="'/airInternational'">国际机票 </router-link>
            </li>
            <li>
              <router-link :to="'/airNational'">国内机票 </router-link>
            </li>
            <li>
              <router-link :to="'/movie'">电影票 </router-link>
            </li>
          </ul>
        </li>
        <li class="el">
          <ul>
            <li><p class="el_title">关于亿旅</p></li>
            <li>
              <router-link :to="'/airInternational'">公司介绍 </router-link>
            </li>
            <li>
              <router-link :to="'/airNational'">企业文化 </router-link>
            </li>
            <li>
              <router-link :to="'/movie'">行业资质 </router-link>
            </li>
          </ul>
        </li>
        <li class="el">
          <ul>
            <li>
              <p
                class="el_title el_title_underline"
                @click="onclickToConnection"
              >
                联系我们
              </p>
            </li>
            <li>客服热线: 028-64722996</li>
            <li>
              合作邮箱:
              <span>jaketang@ylvtrip.com </span> <br />
              <span> davezhang@ylvtrip.com </span>
            </li>
            <li>
              微&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;信:
              <span>19113908696（唐先生） </span>
              <br />
              <span>19115921051（张先生） </span>
            </li>
          </ul>
        </li>
        <li class="el">
          <ul>
            <li>
              <p class="el_title">关注我们</p>
            </li>
            <li>
              <img src="../../assets/vxLogo.jpg" width="120px" height="120px" />
            </li>
          </ul>
        </li>
      </ul>

      <div class="copyRight">
        <span>Copyright&copy; 2022</span>
        <span>成都亿旅科技有限公司</span>
        <span class="copyrightText">
          <a
            target="_blank"
            href="https://beian.miit.gov.cn/#/Integrated/index"
          >
            <span class="copytext">
              <span>蜀ICP备2021026838号</span>
            </span>
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";


export default {
  data() {
    return {
      topShow: false,
      currentIndex: null,
      telShow: false,
      list: [
        {
          img: require("../../assets/image/hotelAPI.png"),
          p1: "酒店接口",
          p2: "用户输入的酒店信息关键进行查询，获取酒店门头照片、地址、房价、入住时间等酒店信息，同时提供创建、取消、支付订单并输出订单详情信息等服务。",
        },
        {
          img: require("../../assets/image/API.png"),
          p1: "API接口服务",
          p2: "提供全套现成的国内国际机票业务API接口，为用户开通国际机票查询订购功能的需求。",
        },
        {
          img: require("../../assets/image/insuranceAPI.png"),
          p1: "保险服务",
          p2: "境内境外旅游险、航意险、公共交通险在线购买，价格低，安全省心。",
        },
        {
          img: require("../../assets/image/hotelAPI.png"),
          p1: "酒店接口",
          p2: "用户输入的酒店信息关键进行查询，获取酒店门头照片、地址、房价、入住时间等酒店信息，同时提供创建、取消、支付订单并输出订单详情信息等服务。",
        },
        {
          img: require("../../assets/image/API.png"),
          p1: "API接口服务",
          p2: "提供全套现成的国内国际机票业务API接口，为用户开通国际机票查询订购功能的需求。",
        },
        {
          img: require("../../assets/image/insuranceAPI.png"),
          p1: "保险服务",
          p2: "境内境外旅游险、航意险、公共交通险在线购买，价格低，安全省心。",
        },
      ],
      serviceList: [
        [
          {
            img: require("../../assets/image/internationalAPI.png"),
            p1: "国际机票接口",
            p2: "国际机票接口，可通过API接口获取订单信息，回填票号，调整税费等一系列与订单相关的操作，可以与商家系统无缝对接，减少操作次数，提高效率。",
          },
          {
            img: require("../../assets/image/domesticAPI.png"),
            p1: "国内机票接口",
            p2: "国内机票接口在提供国内航班查询、验舱验价等API数据查询服务同时，亦向开发者提供了预订下单、支付出票、退票、改签等API预订服务。",
          },
          {
            img: require("../../assets/image/movieAPI.png"),
            p1: "电影票接口",
            p2: "电影票接口，覆盖全国院线的低价票源，支持多种售卖模式，满足客户的不同业务要求和购票需求。",
          },
        ],
        [
          {
            img: require("../../assets/image/hotelAPI.png"),
            p1: "酒店接口",
            p2: "用户输入的酒店信息关键进行查询，获取酒店门头照片、地址、房价、入住时间等酒店信息，同时提供创建、取消、支付订单并输出订单详情信息等服务。",
          },
          {
            img: require("../../assets/image/API.png"),
            p1: "API接口服务",
            p2: "提供全套现成的国内国际机票业务API接口，为用户开通国际机票查询订购功能的需求。",
          },
          {
            img: require("../../assets/image/insuranceAPI.png"),
            p1: "保险服务",
            p2: "境内境外旅游险、航意险、公共交通险在线购买，价格低，安全省心。",
          },
        ],
      ],
      fixedArr: [
        {
          imgShowName: require("../../assets/image/telShow.png"),
          // imgShowName:'telShow',
          description: "电话咨询",
          imgName: "telconsultation",
          isImage: false,
          detail: "18780167804",
        },
        {
          imgShowName: require("../../assets/image/vxShow.png"),
          imgName: "vxconsultation",
          description: "微信咨询",
          isImage: true,
          detail: require("../../assets/image/vxShowLogo.png"),
        },
        {
          imgShowName: require("../../assets/image/qqShow.png"),
          imgName: "qqconsultation",
          description: "QQ咨询",
          isImage: false,
          detail: "2291182207",
        },
      ],
      screenWidth: 1290,
      // imgArr: [
      //   { name: require("../../assets/international.png") },
      //   { name: require("../../assets/domestic.jpg") },
      //   {
      //     name: require("../../assets/cinema2.jpg"),
      //   },
      // ],
      adlist1: [
        {
          name: "ad1",
          title: "精准数据",
          description: "实时数据，速度精准，无缓存",
        },
        {
          name: "ad2",
          title: "接口稳定",
          description: "高并发，低延时，支持亿级日 API访问量",
        },
        {
          name: "ad3",
          title: "响应速度快",
          description: "毫秒响应，及时获取数据",
        },
      ],
      adlist2: [
        {
          name: "ad4",
          title: "专业服务",
          description: "专业技术人员，一对一服务对接",
        },
        {
          name: "ad5",
          title: "标准接口",
          description: "规范的接口文档",
        },
        {
          name: "ad6",
          title: "消费明细",
          description: "消费明细清晰可查",
        },
        {
          name: "ad7",
          title: "持续更新",
          description: "及时更新，不断注入新产品",
        },
      ],
    };
  },
  created() {
    // this.getWidth();
    this.initSwiper();
  },
  // mounted() {
  //   window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
  // },

  methods: {
    // 初始化广告轮播
    initSwiper() {
      const _this = this;
      _this.$nextTick(() => {
        new Swiper(".swiper-container", {
          effect: "fade",

          // width: 1000,
          // height: 1000,
          // slidesPerView : 2,
          direction: "horizontal", //轮播图轮播方向，horizontal为横向，
          loop: true, // 循环模式选项
          spaceBetween: 0, // 左右距离，每一张图之间的间距，不用在css中设置margin了
          slidesPerView: 0,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },

          //默认一个屏幕显示几张图，必须auto！要不轮播到最后一张图时，右侧会是空白，很丑！
          centeredSlides: true, // 每屏，居中显示
          // autoplay: true, // 自动轮播
          // delay: 1000,

          // autoplay: {
          // 	disableOnInteraction: false,
          // 	//操作swiper之后，是否禁止autoplay，默认为true，如不写此属性，在点击轮博或者活动轮播后，会停止自动轮播
          // },
          //下面两个属性一般是数据从后台请求后或者点击轮播图片跳转页面后，需要初始化swiper
          observer: true, // 修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, // 修改swiper的父元素时，自动初始化swiper
          // 如果需要分页器
          // pagination: {
          // el: '.swiper-pagination',
          // },
          pagination: {
            el: ".swiper-pagination",
            // type: "custom",
            type: "bullets",
            bulletElement: "li",
            clickable: true, //点击切换分页器

            hideOnClick: false,

            renderCustom: function (swiper, current, total) {
              console.log(total);
              var customPaginationHtml = "";
              for (var i = 0; i < total; i++) {
                //判断哪个分页器此刻应该被激活
                console.log(i);
                if (i == current - 1) {
                  customPaginationHtml +=
                    '<span class="swiper-pagination-customs swiper-pagination-customs-active"></span> ';
                } else {
                  customPaginationHtml +=
                    '<span class="swiper-pagination-customs"></span>';
                }
              }
              return customPaginationHtml;
            },
          },
          // for (var i = 0; i < total; i++) {
          //   //判断哪个分页器此刻应该被激活
          //   if (i == current - 1) {
          //     customPaginationHtml +=
          //       '<span class="swiper-pagination-customs swiper-pagination-customs-active"></span>';
          //   } else {
          //     customPaginationHtml +=
          //       '<span class="swiper-pagination-customs"></span>';
          //   }
          // }
          // return (
          //   '< span class= "swiperPag" > ' + customPaginationHtml + "</> "
          // );
          //   },
          // },
        });
      });
    },
    onclickToConnection() {
      this.$router.push({
        name: "company",
        params: {
          isConnection: true,
        },
      });
    },

    goTo(path) {
      this.$router.push(path);
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 800) {
        this.topShow = true;
      }
      if (scrollTop <= 0) {
        this.topShow = false;
      }
    },

    onclickToTop() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log(scrollTop);
      if (scrollTop > 0) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    },
    onclickTo(val) {
      switch (val) {
        case 0:
          this.$router.push("/airInternational");
          break;

        case 1:
          this.$router.push("/airNational");
          break;
        case 2:
          this.$router.push("/movie");
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-box {
  background-image: url("../../assets/image/background.png");
  background-size: 100% 710px;
  background-repeat: no-repeat;
  width: 100%;
  margin-top: -80px;
  height: 710px;
  min-width: 1400px;
}

.service {
  .header_bottom {
    position: absolute;
    top: -41px;
    min-width: 1200px;
    width: 100%;
    .box {
      display: flex;
      z-index: 99;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      margin: 0px auto;
      width: 1185px;
      .container {
        width: 381px;
        z-index: 99;
        height: 160px;
        width: 387px;
        background-color: white;
        border-radius: 8px 8px 8px 8px;
        .container_padding {
          display: flex;
          justify-content: center;
          margin: 3px auto;
          width: 381px;
          height: 160px;

          .icon {
            vertical-align: super;
            align-self: center;
            align-content: center;
          }

          .text {
            margin: 35px 20px;

            p {
              text-align: start;
              padding: 5px 0;
              margin: 0;
            }

            .text_title {
              font-size: 22px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #000000;
            }

            .text_container {
              font-size: 16px;
              font-family: PingFang SC-Regular, PingFang SC;
              color: #333333;
            }
          }
        }
      }
    }
  }
  position: absolute;
  top: 650px;
  width: 100%;
  height: 803px;
  background: #f2f6fe;
  border-radius: 0px 0px 0px 0px;
  .title {
    margin-top: 155px;
    text-align: center;
    font-size: 46px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    margin-bottom: 67px;
    color: #333333;
  }
  .swiper-container {
    overflow: hidden !important;
    // width: 70%;
    // width: 1130px;
    // width: 80%;
    margin: 0 auto;
    //设置每个轮播的宽度，数值越小，两边显示的会越大
    height: 400px;
    position: relative;
  }
  .swiper-container .swiper-wrapper .swiper-slide {
    border-radius: 10px;
    display: block;
  }
  .swiper-container .swiper-wrapper .swiper-slide img {
    // width: 100%;
    // height: 100%;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 50px;
  }
  .swiper-slide-active .bookName {
    display: block;
  }
  .swiper-pagination-bullet {
    width: 15px;
    background: #1a2b3c none repeat scroll 0 0;
    border-radius: 30%;
    display: inline-block;
    height: 15px;
    opacity: 1;
    transition: all 0.3s;
    cursor: pointer;
    bottom: 0;
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
  }

  /*自定义分页器激活时的样式表现*/
  ::v-deep .swiper-pagination-bullet-active {
    width: 15px;
    // height: 15px;
    background: #feb500 !important;
    border-radius: 7.5px;
    // border: medium none;
    // opacity: 1;
  }

  .api {
    display: inline-grid;
    margin: 10px 6px;
    width: 381px;
    height: 349px;
    background: #ffffff;

    p {
      text-align: start;
      margin: 0 auto;
    }

    .api_title {
      font-size: 19px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: bold;
      text-align: start;
      margin: 0;
      padding-left: 10px;
      color: #333333;
      padding-top: 10px;
      padding-bottom: 3px;
      border-radius: 0px 0px 0px 0px;
    }

    .api_content {
      width: 361px;
      height: 79px;
      font-size: 13px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
  }
}

.bottom {
  position: absolute;
  top: 2557px;
  height: 282px;
  width: 100%;
  color: #f3f5f6;
  background: #111a55;
  font-size: 14px;
  .bottom_title {
    padding: 50px 0 0px 0px;

    width: 85%;
    margin: 0 auto;
    display: inline;
    display: flex;
    justify-content: center;
    li {
      display: block;
    }
    .el {
      .el_title {
        text-align: start;
        font-size: 20px;
        font-style: normal;
        padding-left: 0;
        padding-bottom: 7px;
      }
      width: max-content;
      margin: 0 47px;
      ul {
        width: 100%;
        text-align: center;
        display: inline-block;
      }
      li {
        padding: 5px 0;
        display: block;
        width: max-content;
      }
      .logoName {
        margin-left: 10px;
      }
      li p {
        display: inline-block;
        font-style: italic;
        font-size: 24px;
        padding-left: 3px;
      }
      .el_title_underline {
        text-decoration: underline;
      }
    }
    .el:nth-child(4) {
      li {
        text-align: start;
        width: 250px;
      }
      ul li {
        span:last-child {
          display: inline-block;
          padding: 3px 0;
          padding-left: 62px;
        }
      }
    }
  }
  ul li {
    a {
      text-decoration: none;
      color: #f3f5f6;
    }

    padding: 4px 0;
    cursor: pointer;
  }

  ul li:first-child {
    border: none;
    // text-align: justify;
    font-size: 18px;
    font-weight: bolder;
  }
  .copyRight {
    color: #bababa;

    span {
      padding: 0 2px;
    }
    .copyrightText {
      color: #bababa;

      a {
        text-decoration: none;

        color: #bababa;
        .copytext {
          font-size: 15x;
        }
      }
    }
  }
}

.carousel {
  position: absolute;
  top: 0;
  width: 100%;
}
.title {
  font-size: 32px;
  padding: 17px 0 0 0;
  color: #111;
}

.advantage {
  background: #f2f6fe;
  position: absolute;
  top: 1453px;
  width: 100%;
  height: 728px;
  margin: 0 auto;
  .title {
    background: url(../../assets/image/advantage.png) no-repeat; //
    background-size: 100%;
    background-size: 100% 188px;
    height: 188px;
    p {
      line-height: 131px;
      font-size: 32px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
  }
  .ad_content {
    height: 504px;
    // width: 85%;
    // margin: 0 139px;
    margin: 0 auto;
    width: 1170px;
    border-radius: 5px 5px 5px 5px;

    margin-top: -60px;
    background: #ffffff;
    ul {
      padding: 0;
      padding-top: 38px;
      display: flex;
      justify-content: center;
    }
    ul li {
      margin: 0 10px;
      .advantage_float {
        display: block;
        float: left;
        width: 246px;
        background-color: rgba(255, 255, 255, 0.8);
        height: 200px;
        .icon {
          display: flex;
          flex: 1;
          align-self: center;
          align-content: center;
          display: inline-block;
          width: 100px;
          height: 100px;
          margin-bottom: 3px;
        }
        .text {
          .text_title {
            font-size: 18px;
          }
          .text_container {
            width: 185px;
            margin: 0 auto;
            font-size: 13px;
            color: #999;
            padding-top: 4px;
          }
        }
      }
    }
  }
}

.step {
  position: absolute;
  top: 2153px;
  background: #f2f6fe;
  width: 100%;
  height: 600px;
  // width:max-content;
  margin-bottom: 20px;
  padding: 25px 0 0 0;
  p {
    padding: 0px 0 40px 0;
  }
  ul {
    width: 1257px;
    padding: 0;
    // margin: 0 92px;
    margin: 0 auto;
    // display: inline-block;
    display: flex;
    // justify-content: center;
  }
  ul li {
    padding: 0 57px 0 43px;
    width: 110px;
  }
  .step_container {
    // width: 70%;
    width: 1257px;
    margin: 10px auto;
    padding: 25px 0;
    ::v-deep .el-step {
      flex-basis: 16.68%;
      margin: 0 8px 0 0 !important;
      // position: relative;
      // flex-shrink: 1;
    }

    ::v-deep .el-step__line {
      background-color: #fec86d !important;
    }
    ::v-deep .el-step__icon.is-icon {
      background-color: #f2f6fe;
      width: 24px !important;
    }
    ::v-deep .el-step__title.is-wait {
      color: #000000;
      font-size: 22px;
      font-style: italic;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: normal;
    }
    ::v-deep .el-step__description.is-wait {
      font-family: PingFang SC-Medium, PingFang SC;
      padding-top: 7px;
      font-size: 16px;
      color: #000000;
    }
    // ::v-deep .el-steps--horizontal{

    // }
  }
}
.naviFixed {
  position: fixed;
  top: 30%;
  right: 8px;
  z-index: 999;
  .background_up {
    margin-bottom: 10px;
    border-radius: 4px 4px 4px 4px !important;
  }

  .background:first-child {
    border-radius: 4px 4px 0px 0px;
  }
  .background:last-child {
    border-radius: 0px 0px 4px 4px;
  }
  .background {
    list-style: none;
    position: relative;
    background: #ffffff;
    .wrap_btn1 {
      width: 81px;
      height: 81px;
      cursor: pointer;
      .wrap {
        padding-top: 24px;
        .svg {
          width: 25px;
          height: 25px;
          line-height: 25px;
          display: block;
          margin: 0 auto;
        }
      }
    }
    .wrap_btn {
      width: 81px;
      height: 81px;
      cursor: pointer;
      .wrap {
        padding-top: 18px;
        .svg {
          width: 25px;
          height: 25px;
          line-height: 25px;
          display: block;
          margin: 0 auto;
        }
        span {
          display: block;
          font-size: 13px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          margin-top: 9px;
          color: #333333;
        }
      }
    }

    .wrap_left1 {
      vertical-align: center;
      background-color: white;
      position: absolute;
      width: 145px;
      padding-right: 3px;
      top: 0;
      box-shadow: 0px 3px 5px 1px rgba(67, 97, 183, 0.3);
      border-radius: 4px 4px 4px 4px;

      left: -162px;
      z-index: 999;

      .wrap_left11 {
        margin: 0 0 10px 7px;
        text-align: start;
        p {
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #fd8843;
          padding: 10px 0 10px 0;
        }
        span {
          margin: 0 auto;
          display: block;
          font-size: 10px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
        }
        span:nth-child(3) {
          margin-bottom: 10px;
        }
      }
    }
    .wrap_left {
      vertical-align: center;
      background-color: white;
      position: absolute;
      width: 110px;
      padding-right: 10px;
      top: 20px;
      left: -135px;

      p {
        text-align: center;
      }
    }

    .wrap_left::before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      border-left: 10px solid white;
      border-top: 10px solid transparent;
      border-right: 0px solid white;
      border-bottom: 10px solid transparent;
      right: -10px;
      top: 9px;
    }

    .wrap_btnActive {
      width: 81px;
      height: 81px;
      background: linear-gradient(180deg, #f58a2e 0%, #ffcd72 100%);
      // border-radius: 5px 0px 0px 0px;
      opacity: 1;

      .wrap {
        padding-top: 18px;
        // line-height: 81px;
        .svg {
          width: 25px;
          height: 25px;
          display: block;
          margin: 0 auto;
          color: #ffffff;
        }
        span {
          display: block;
          font-size: 9px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          margin-top: 9px;
          color: #ffffff;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .header_box { background-size: 100% 766px; }

  .service {
    width: 1400px;
    .swiper-container {
      width: 1400px;
    }
  }
  .advantage {
    width: 1400px;
  }
  .step {
    width: 1400px;
  }
  .bottom {
    width: 1400px;
  }
}
@media screen and (min-width: 1460px) {
  .header-box {
    background-image: url("../../assets/image/bg2.png");
    background-size: 100% 766px;
  }
}
</style>
