import Vue from "vue";
import SvgIcon from "@/components/SvgIcon";
Vue.component("svg-icon", SvgIcon); //全局注册SvgIcon

// const requireAll = (requireContext) =>
//   requireContext.keys().map(requireContext);
// const req = require.context("./svg", false, /\.svg$/);
// const navreq = require.context("./navsvg", false, /\.svg$/);
// const uisvg = require.context("./uisvg", false, /\.svg$/);
// requireAll(navreq);
// requireAll(uisvg);
// requireAll(req);

const req = require.context("@/assets/icons/svg", false, /\.svg$/);
const requireAll = (requireContext) => {
  // requireContext.keys()数据：['./404.svg', './agency.svg', './det.svg', './user.svg']
  requireContext.keys().map(requireContext);
};
requireAll(req);
