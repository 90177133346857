import Vue from "vue";
import VueRouter from "vue-router";
import homeComponent from "../views/index/homeComponent";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "homeComponent",
    component: homeComponent,
    meta: {},
  },
  {
    path: "/airInternational",
    name: "airInternational",
    component: () => import("../views/air/airInternational.vue"),
  },
  {
    path: "/airNational",
    name: "airNational",
    component: () => import("../views/air/airNational.vue"),
  },

  {
    path: "/movie",
    name: "movie",
    component: () => import("../views/movie/index.vue"),
    // components: (resolve) => require(["@/views/train/index.vue"], resolve),
  },
  {
    path: "/company",
    name: "company",
    component: () => import("../views/company/index.vue"),
    // components: (resolve) => require(["@/views/train/index.vue"], resolve),
  },
];
// 创建一个路由对象
const router = new VueRouter({
  mode: "hash",
  routes,
});

export default router;
// 导出路由
