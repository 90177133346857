import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/icons/index'
// import "lib-flexible/flexible.js";
// import "swiper/swiper-bundle.min.css";
// import "swiper/css/bundle"; //"./swiper-bundle.min.css"
import "swiper/css/swiper.min.css";
// import Swiper from "swiper"


Vue.config.productionTip = false;
Vue.use(ElementUI)
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
